import React from "react";
import { Link } from "gatsby";

import "../scss/capability.scss";

class Capability extends React.Component {
  render() {
    return (
      <div className="capability-component">
        <Link
          to={this.props.capabilitylink}
          className="no-decoration"
          title={this.props.capabilitytitle}
        >
          <div className="col-one">
            <h5 className="col-one-heading">
              {this.props.capabilitytitle}
            </h5>
            <img className="col-one-image" alt={this.props.capabilitytitle} src={this.props.capabilityimage}/>
            <h6 className="col-one-link">View More</h6>
          </div>
        </Link>
      </div>
    )
  }
}

export default Capability
